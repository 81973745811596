import React from "react";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";
import { Container } from "../../components/global";
import Header from "../../components/sections/terms-privacy/header";
import Footer from "../../components/sections/footer";
import { Content, Section, P, H1, H2, UL, OL } from "../../styles/TCandPrivacyStyles";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <P fontSize={["md", null, "lg"]}>
          At OPay the privacy of our Data Subjects’ Personal Data is of utmost importance to us. In line with our
          resolution, we have developed this Privacy Policy to explain your privacy rights regarding our collection,
          use, sharing and protection of your Personal Data when you visit our website, premisses or use our digital
          platforms.
        </P>
        <P fontSize={["md", null, "lg"]}>
          This Privacy Policy and its Addendum(s) (<span>“Privacy Policy”</span>) describe how OPay Digital Services
          (Private) Limited, and its affiliates (collectively “<span>OPay</span>”, “<span>we</span>”, “<span>us</span>”
          or “<span>our</span>”) collect, use, process and disclose your Personal Data through the use of OPay’s Apps
          and Websites (including all mobile applications and websites operated by OPay (respectively “Platform”),
          products, features and other services globally (collectively, “Services”).
        </P>
        <P fontSize={["md", null, "lg"]}>
          This Policy applies to our customers, passengers, agents, vendors, suppliers, partners (such as merchant
          partners), contractors and service providers who has full legal capacity (collectively “<span>you</span>”, “
          <span>your</span>” or “<span>yours</span>”) to give consent to this Policy.
        </P>
        <P fontSize={["md", null, "lg"]}>
          Your consent means any freely given, specific, informed and unambiguous indication of your wishes by which you
          signify agreement to the processing of Personal Data relating to you, through ticking the “Read and Agree” box
          of this Policy and clicking the “Agree” button on OPay APPs or Websites.
        </P>
      </Content>

      <Content>
        <Section>
          <H1>1. Modification of the Privacy Policy</H1>
          <P>
            We may modify this Privacy Policy at any time. If we make any changes and post the new Private Policy on the
            Platform, the new Privacy Policy will be effective immediately. You agree that any information we hold about
            yours (as described in this Privacy Policy and whether or not collected prior to or after the new Privacy
            Policy became effective) will be governed by the latest version of the Privacy Policy. You can determine
            when this Privacy Policy was last revised by referring to the “Effective Date” above.
          </P>
        </Section>

        <Section>
          <H1>2. Including information</H1>

          <P>
            We may collect the following categories of personal information from and about you, you understand that
            information we collect may various according to certain productions you choose from our services, and we
            commit that the personal information we collect from you shall be limited to minimum requirements for
            providing services to you:
          </P>

          <OL>
            <li>
              <span>Demographic information</span> : such as your age, birth date, and gender.
            </li>

            <li>
              <span>Commercial information</span> : this information may include records of personal property, products
              or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
              Commercial information may also include information about the utilities you use, rewards and points-based
              incentive programs, program membership information, and information about your mobile device and carrier.
            </li>
            <li>
              <span>Business information</span> : this information may include information about your business, such as
              legal business name and company practice name, and other information about your company or practice such
              as fax, telephone number, website, relevant email addresses, physical address, business-related licensing
              information such as a license number, federal tax identification or social security number, and business
              or practice bank account information.
            </li>
            <li>
              <span>Location information</span> : such as information related to your specific location information that
              is provided through the GPS functionality on mobile devices used to access the Platform.
            </li>
            <li>
              <span>Financial information</span> : such as information collected from you as needed to process payments,
              or as provided by you to administer your participation in optional services and programs, such as your
              payment and bank information, wire transfer information, credit card number, debit card number, full
              credit report, transactional information for financial accounts, account information including interest
              rates and balances, income information, and any other financial information available.
            </li>
            <li>
              <span>Professional, Education, or Employment-related information</span> : include work history, employer
              and employment information, profession, job title, work address and work phone number, and other
              information related to your profession.
            </li>
            <li>
              <span>Audio, Visual, and Electronic information</span> : include a picture of you, digital or digitized
              signature, or voice recording from phone calls. We can also collect any other communications between you
              and us.
            </li>
            <li>
              <span>Internet and network activity information</span> : such as information about your browsing behavior,
              search history, and interactions with websites and advertisements, including data from cookies, pixel
              tags, and web beacons.
            </li>
            <li>
              <span>Inferences regarding preferences and other characteristics</span> : such as our assessment of the
              types of products or services you may have an interest in.
            </li>
            <li>
              <span>Other sensitive information</span> : this information includes other information related to your
              transactions with us or provided to us through linked accounts and systems, such as information about
              medical or educational services purchased with a loan, insurance information such as automotive insurance
              numbers relating to an automobile loan or health insurance information.
            </li>
          </OL>
        </Section>

        <Section>
          <H1>3. Collection of Information</H1>

          <P>We may collect personal information about you from a variety of sources, including:</P>
          <UL>
            <li>
              <span>From you</span> : We collect information that you submit to us. We will collect any information you
              voluntarily provide, and we may also request optional information to support your use of the Services. We
              also collect any information that you voluntarily enter into any postings, comments, or forums within the
              Platform.
            </li>
            <li>
              <span>From your device</span> : When you use the Platform, we may collect information about the devices
              you use to access the Platform, including hardware model, operating system and version, Internet Protocol
              (“IP”) address, and other unique device identifiers, mobile network information, and information about the
              device’s interaction with our Platform. We also collect information about how you use our Platform,
              including your access times, browser types, and language. Note that this Privacy Policy covers only how we
              use information collected via cookies; it does not cover the use of such information by third parties.
            </li>
            <li>
              <span>Location information</span> : We may collect different types of information about your location,
              including general information, such as the city, state, and/or zip code associated with your IP Address,
              and, if you agree, more specific location information that is provided through the GPS functionality on
              mobile devices used to access the Platform. You may be able to disallow our use of certain location data
              through your device or browser settings, for example, by disabling “Location” services for our application
              in iOS privacy settings.
            </li>
            <li>
              <span>Social media networks and other third parties</span> : We may obtain information about you or your
              use of the Platform from third party sources, such as our vendors, like web hosting providers, analytics
              providers, or advertisers. When you access the Platform through social media networks and other third
              party platforms, you are authorizing us to collect, store, and use such information and content in
              accordance with this Privacy Policy. Please keep in mind that any information provided to us by a third
              party may also be subject to that third party’s privacy policy.
            </li>
          </UL>
        </Section>

        <Section>
          <H1>4. Use of Personal Information?</H1>

          <P>We may use information collected as described in this Privacy Policy to:</P>

          <UL>
            <li>
              Operate, maintain, and improve the Platform and other programs, features, and functionality related to the
              Platform.
            </li>
            <li>
              Provide you with interest-based ads, push notifications, communications, and offers for services from us
              and our business partners, including based on your precise geolocation.
            </li>
            <li>
              Process your services application, facilitating transactions, for verification purposes, regulatory
              requirements.
            </li>
            <li>
              Evaluate your eligibility for certain types of offers, products, or services that may be of interest to
              you, and analyze advertising effectiveness.
            </li>
            <li>Answer your questions and respond to your requests.</li>
            <li>Perform analytics and conduct customer research.</li>
            <li>
              Communicate and provide additional information that may be of interest to you about us and our business
              partners, sometimes by combining your information with information we obtain from other sources.
            </li>
            <li>
              Send you reminders, technical notices, updates, security alerts, support and administrative messages,
              service bulletins, marketing messages, and requested information, including on behalf of our business
              partners.
            </li>
            <li>
              Administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored by
              us or our business partners.
            </li>
            <li>
              Manage our everyday business needs, such as administration of the Platform, forum management, fulfillment,
              analytics, fraud prevention, and enforcement of our corporate reporting obligations and Terms of Use, or
              to comply with the law, including but not limited to Know Your Customer, Anti-Money Laundering, Anti-Fraud
              requirements.
            </li>
            <li>Verify your requests made pursuant to this Privacy Policy.</li>
            <li>
              Enhance other information we have about you to help us better understand you and determine your interests.
            </li>
          </UL>

          <P>
            We also may use information collected as described in this Privacy Policy with your consent or as otherwise
            required or permitted by law.
          </P>
          <P>
            If you use any features on the Platform to send information about a product or service to another person, we
            will also collect the personal information of that other person to the extent disclosed by you and may
            contact them using the information you provided us.
          </P>
        </Section>

        <Section>
          <H1>5. Disclosure or Sharing of Personal Information</H1>

          <P>We may share your personal information as follows:</P>

          <UL>
            <li>
              As required to comply with the law or legal obligations, such as to comply with legal orders and
              government requests, or as needed to support auditing, initial public offerings, compliance, and corporate
              governance functions.
            </li>
            <li>
              In response to a subpoena, or similar legal process, including to law enforcement agencies, regulators,
              and courts in the Nigeria and other countries where we operate.
            </li>
            <li>
              With our vendors who perform a variety of services and functions for us, such as data storage, order
              fulfillment, transmitting emails, SMS, and managing digital content. We may share your information with
              such vendors subject to confidentiality obligations consistent with this Privacy Policy and on the
              condition that the service providers use your information only on our behalf and pursuant to our
              instructions.
            </li>
            <li>
              In the event we go through a business transition such as a merger, acquisition by another company,
              bankruptcy, reorganization, or sale of all or a portion of our assets.
            </li>
            <li>
              To combat fraud or criminal activity, and to protect our rights, users, and business partners, or as part
              of legal proceedings affecting us.
            </li>
            <li>
              With our affiliates and subsidiaries who may only use the personal information for the purposes described
              in this Privacy Policy
            </li>
            <li>
              If the information has been aggregated, de-identified, or otherwise does not identify you personally; and
              otherwise with your consent.
            </li>
            <P>
              We may disclose the all categories of personal information we received for our own business and
              operational purposes.
            </P>
            <P>
              We may disclose your personal information in exchange for services, insights, or other valuable
              consideration.
            </P>
          </UL>
        </Section>

        <Section>
          <H1>6. Security of Personal Information</H1>

          <P>
            We have implemented an information security program that contains administrative, technical, and physical
            controls that are designed to reasonably safeguard personal information. No method of transmission over the
            Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its
            absolute security. If you have any questions about security on our Website, you can contact us in accordance
            with Clause 12.
          </P>
        </Section>

        <Section>
          <H1>7. Cookies</H1>

          <P>
            We together with our marketing, analytics and technology partners, uses certain monitoring and tracking
            technologies (such as cookies, beacons, pixels, tags and scripts). These technologies are used in order to
            maintain, provide and improve our Platform on an ongoing basis, and in order to provide our visitors, our
            merchants, and their customers with a better experience. The four main functions of these tools are as
            below:
          </P>

          <UL>
            <li>
              <span>Site functionality cookies</span> : these cookies allow you to navigate the site and use our
              features.
            </li>
            <li>
              <span>Site analytics cookies</span> : these cookies allow us to measure and analyze how our customers use
              the site, to improve both its functionality and your shopping experience.
            </li>
            <li>
              <span>Customer preference cookies</span> : when you are browsing or shopping on our Platform, these
              cookies will remember your preferences (like your language or location).
            </li>
            <li>
              <span>Targeting or advertising cookies</span> : these cookies are used to deliver ads relevant to you.
            </li>
          </UL>
          <P>
            By using our site on Platform, you agree to us placing these sorts of cookies on your device and accessing
            them when you visit the site in the future. You may manage how your browser handles cookies by adjusting its
            privacy and security settings. Browsers are different, so refer to instructions related to your browser to
            learn about cookie-related and other privacy and security settings that may be available
          </P>
        </Section>

        <Section>
          <H1>8. Social Community Areas</H1>

          <P>
            The Platform may be accessible through or contain connections to areas where you may be able to publicly
            post information, communicate with others such as discussion boards or blogs, review products and merchants,
            and submit media content. Prior to posting in these areas, please read our Terms of Use carefully. All the
            information you post may be accessible to anyone with Internet access, and any personal information you
            include in your posting may be read, collected, and used by others. We recommend that you do not post any
            personal information in the social community areas.
          </P>
        </Section>

        <Section>
          <H1>9. Privacy Practices of Third Parties</H1>

          <P>
            This Privacy Policy only addresses the use and disclosure of information by us through your interaction with
            the Platform. Other websites that may be accessible through links from the Platform and our co-branded
            websites may have their own Privacy Policies and personal information collection, use, and disclosure
            practices. Our business partners may also have their own privacy policies. We encourage you to familiarize
            yourself with the privacy policies provided by these other parties prior to providing them with information
            or taking advantage of a sponsored offer or promotion.
          </P>
        </Section>

        <Section>
          <H1>10. Business Transfer and Corporate Changes</H1>
          <P>
            We reserve the right to disclose, transfer, or license any and all information related to the Platform,
            including personal information:
          </P>
          <UL>
            <li>
              To a subsequent owner, co-owner, or operator of the Platform or any portion or operation related to part
              of Platform.
            </li>
            <li>
              in connection with a corporate merger, consolidation, or restructuring, the sale of substantially all of
              our stock and/or assets, or other corporate change, including, without limitation, during the course of
              any due diligence process.
            </li>
          </UL>
        </Section>

        <Section>
          <H1>11. Children&apos;s Privacy</H1>

          <P>
            The Platform is a general audience site not directed at children under the age of 18. By using our Platform,
            you represent that you are at least eighteen years old and understand that you must be at least eighteen
            years old in order to create an account and purchase the goods or services advertised through the Platform.
          </P>
        </Section>

        <Section>
          <H1>12. International Transfers of Personal Information</H1>

          <P>
            In connection with our provision of the Platform and its connected services, under certain circumstance, we
            may transfer your personal information to countries outside of the, including to countries that may not
            provide the same level of data protection as your home country. We take appropriate steps to ensure that
            recipients of your personal information are bound to duties of confidentiality and we implement appropriate
            measures to ensure your personal information will remain protected in accordance with this Privacy Policy,
            such as standard contractual clauses.
          </P>
        </Section>

        <Section>
          <H1>13. Contact Us</H1>

          <P>
            You fully understand and acknowledge that we are entitled to final interpret the terms of this Privacy
            Policy. Please contact us if you have any questions or comments about our privacy practices or this Privacy
            Policy.
          </P>
          <P>
            You can reach us online at <a href="mailto:info@opay-inc.com">info@opay-inc.com</a>
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default PrivacyPolicy;

const pageData = {
  headerData: {
    captionText: "Privacy Policy",
    subTitleText: "Effective Date: 14th September 2021",
  },
};
